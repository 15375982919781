import Styles from "./Navbar.module.css";
import Link from "next/link";
import { useRouter } from "next/router";
import Navdesk from "./Nav Desk";
import { useState, useEffect } from 'react';

const Nav = (...props) => {

  const router = useRouter();
  
  const [active, setActive] = useState(props.title);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset >= sticky);
    };

    const sticky = document.getElementById("navbar").offsetTop;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <Navdesk className="header" />
      <div id="navbar" className={isSticky ? "sticky" : ""}>
      <div className="container-fluid p-0 d-none d-lg-block">
        <nav className={`${Styles.navstyle} navbar navbar-expand-lg py-0`} style={{ background: "#F4F6F8 0% 0% no-repeat padding-box" }}>
          <div className="container">

            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="custom-border1">
                  <Link href="/" onClick={() => setActive("home")} className={
                    active === "home"
                      ? `${Styles.headertitles} nav-link cursorPointer ${Styles.activeLink} nav-item pe-2 mx-xl-3 mx-lg-2`
                      : `${Styles.headertitles} nav-link cursorPointer nav-item pe-2 mx-xl-3 mx-lg-2`
                  }>
                    <span className="pe-xl-4 pe-lg-3">Home</span>
                  </Link>
                </li>
                <li
                  className={
                    active === "about"
                      ? `${Styles.activeLink} nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1`
                      : "nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1"
                  }
                  id="about"
                  onClick={() => setActive("about")}
                >
                  <a
                    className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer pe-xl-4 pe-lg-3`}
                    data-bs-toggle="dropdown"
                    data-bs-target="#about"
                    href="#"
                  >
                    <span className="pe-2">About{" "}</span>
                  </a>
                  <div
                    className={`${Styles.borderRadius} dropdown-menu cursorPointer`}
                    aria-labelledby="navbarDropdownMenuLink"
                    id="about"
                  >
                    <Link href="/about-us" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                      About Shankar IAS Academy
                    </Link>
                    <Link href="/why-shankar-ias" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Why Shankar IAS Academy
                    </Link>
                    <Link href="/our-infrastructure" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                      Infrastructure
                    </Link>
                    <Link href="/our-faculty" className={`${Styles.headerdropdown} dropdown-item  pb-2 cursorPointer`}>
                      Faculty
                    </Link>
                  </div>
                </li>
                    <li
                      className={
                        active === "upsc"
                          ? `${Styles.activeLink} nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1`
                          : "nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1"
                      }
                      id="upsc"
                      onClick={() => setActive("upsc")}
                    >
                    <a
                      className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer pe-xl-4 pe-lg-3`}
                      data-bs-toggle="dropdown"
                      data-bs-target="#upsc"
                      href="#"
                    >
                      <span className="pe-2">UPSC{" "}</span>
                    </a>
                    <div
                      className={`${Styles.borderRadius} dropdown-menu`}
                      aria-labelledby="navbarDropdownMenuLink"
                      id="upsc"
                    >
                    <Link href="/about-upsc" className={`${Styles.headerdropdown} dropdown-item headerdropdown pt-2 cursorPointer`}>
                        About Civil Service
                    </Link>

                    <Link href="/upsc-admission-anna-nagar" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                       UPSC - Prelims cum Mains (Foundation Course)
                    </Link>

                   
                    
                    <Link href="/upsc-interview" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                       UPSC Civilisation interview 2024
                    </Link>

                    <Link href="/current-affairs-upsc" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    Chakra Current Affairs Program 2024
                    </Link>


                    <Link href="/upsc-mains-test-series" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                        UPSC GS-Mains Test Series 2024 (Moksha)
                    </Link>


                    <Link href="/upsc-optional-test-series" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                       UPSC Optional Test Series 2024
                    </Link>

                    <Link href="/upsc-ifos-optional-test-series" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    UPSC Forestry Optional Test Series 2024
                    </Link>

                    <Link href="/forestry-admission" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    UPSC Forestry Optional
                    </Link>


                    <Link href="/two-year-upsc-program" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                       Sadhana II Year Classroom Programme
                    </Link>
                   
                   

                    <Link href="/upsc-prestorming" className={`${Styles.headerdropdown} dropdown-item headerdropdown cursorPointer`}>
                       UPSC Prelims Test Series 2025
                    </Link>

                    <Link href="/about-forestry" className={`${Styles.headerdropdown} dropdown-item headerdropdown cursorPointer`}>
                        About Forest Service
                    </Link>


                    <Link href="/upsc-environment-book" className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}>
                       UPSC Environment Book Buy Now
                    </Link>
                  
                  </div>
                </li>
                <li
                  className={
                    active === "tnpsc"
                      ? `${Styles.activeLink} nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1`
                      : "nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1"
                  }
                  id="tnpsc"
                  onClick={() => setActive("tnpsc")}
                >
                  <a
                    className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer pe-xl-4 pe-lg-3`}
                    data-bs-toggle="dropdown"
                    data-bs-target="#tnpsc"
                    href="#"
                  >
                    <span className="pe-2">TNPSC{" "}</span>
                  </a>
                    <div
                      className={`${Styles.borderRadius} dropdown-menu cursorPointer`}
                      aria-labelledby="navbarDropdownMenuLink"
                      id="tnpsc"
                    >
                    <Link href="/tnpsc-admissions" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    Gr I, II & IIA Prelims Coaching
                    </Link>

                    <Link href="https://www.shankariasacademy.com/blog/tnpsc-group-i-main-mock-test/" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    TNPSC Group I Mains Mock Test 2024
                    </Link>

                    <Link href="/tnpsc-group-II-IIA-mains-test-series" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    TNPSC Group II/IIA Mains Test Series 2024
                    </Link>

                    <Link href="/tnpsc-group-II-mains-test-series" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    TNPSC Group II Mains Test Series 2024
                    </Link>
                    <Link href="/tnpsc-group-IIA-mains-test-series" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    TNPSC Group IIA Mains Test Series 2024
                    </Link>
                    <Link href="/tnpsc-group-i-mains-test-series" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    TNPSC Group I Mains Test Series 2024
                    </Link>

                    <Link href="/tnpsc-group-i-mains-crash-course" className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}>
                    TNPSC Group I Mains Crash Course 2024
                    </Link>

                   
                  
                    {/*<Link href="tnpsc-group-II-IIA-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                    TNPSC Group II/II-A Test Series 2024
                    </Link> */}
                  
                    <Link href="si-test-series" className={`${Styles.headerdropdown} dropdown-item cursorPointer`}>
                    TNUSRB-SI Test Series 2024 
                    </Link>
                   
             </div>
                </li>
                <li
                  className={
                    active === "banking"
                      ? `${Styles.activeLink} nav-item pe-2 mx-xl-3 mx-lg-2 custom-border1`
                      : "nav-item pe-2 mx-xl-3 mx-lg-2 custom-border1"
                  }
                  id="banking"
                  onClick={() => setActive("banking")}
                >
                  <a href="https://www.shankarbankingacademy.com/" target={"_blank"} rel="noreferrer"
                    className={`${Styles.headertitles} nav-link cursorPointer`}
                  >
                    <span className="pe-xl-4 pe-lg-3">Banking</span>
                  </a>
                </li>
                <li
                  className={
                    active === "learnCorner"
                      ? `${Styles.activeLink} nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1`
                      : "nav-item dropdown pe-2 mx-xl-3 mx-lg-2 w-100 custom-border1"
                  }
                  id="learnCorner"
                  onClick={() => setActive("learnCorner")}
                >
                  <a
                    className={`${Styles.headertitles} nav-link dropdown dropdown-toggle cursorPointer pe-xl-4 pe-lg-3`}
                    data-bs-toggle="dropdown"
                    data-bs-target="#learningCorner"
                    href="#"
                  >
                    <span className="pe-2">Learning Corner</span>
                  </a>
                  <div
                    className={`${Styles.borderRadius} dropdown-menu borderRadius`}
                    aria-labelledby="navbarDropdownMenuLink"
                    id="learningCorner"
                  >
                    <a href="https://www.shankariasacademy.com/postalmaterial/" rel="noreferrer"
                      className={`${Styles.headerdropdown} dropdown-item pt-2 cursorPointer`}
                    >
                      Study Materials
                    </a>
                    <a href="/current-affairs" target={"_blank"} rel="noreferrer"
                      className={`${Styles.headerdropdown} dropdown-item cursorPointer`}
                    >
                      Current Affairs
                    </a>
                    <a href="https://www.iasparliament.com/UPSC-prelims-free-mock-test" target={"_blank"} rel="noreferrer"
                      className={`${Styles.headerdropdown} dropdown-item pb-2 cursorPointer`}
                    >
                      Daily Quiz
                    </a>
                  </div>
                </li>
                <li
                  className={
                    active === "result"
                      ? `${Styles.activeLink} nav-item pe-2 mx-xl-3 mx-lg-2`
                      : "nav-item pe-2 mx-xl-3 mx-lg-2"
                  }
                  id="result"
                  onClick={() => setActive("result")}
                >
                  <Link href="/our-achievers" className={`${Styles.headertitles} nav-link pe-0 cursorPointer`}>
                    Achievements
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      </div>

    </>
  );
};

export default Nav;
